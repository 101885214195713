import { useCallback } from 'react';
import {
  Grid,
  ScrollablePanelContent,
  ToggledSetting,
  ToggledSettings,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import {
  useGetOrganizationOnboardingSettings,
  useUpdateOrganizationOnboardingSettings,
} from '../../../../hooks/reactQuery';

const OnboardingTab = () => {
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();
  const {
    data: settings, isLoading, isError,
  } = useGetOrganizationOnboardingSettings({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });
  const {
    mutate,
    isLoading: isUpdating,
  } = useUpdateOrganizationOnboardingSettings({ useOptimisticUpdates: true });

  const handleSettingChange = useCallback((name: string, checked: boolean) => {
    if (isUpdating) {
      return;
    }
    mutate(
      { ...settings!, [name]: checked },
      {
        onError: () => showError({ text: t('common.somethingWentWrong') }),
        onSuccess: () => {
          showSuccess({ text: t('common.settingsUpdatedToast') });
        },
      },
    );
  }, [isUpdating, mutate, settings, showError, showSuccess, t]);

  if (isError) {
    return null;
  }

  const { skipInstallMobileAppScreen } = settings ?? {};

  return (
    <ScrollablePanelContent p={3}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid container item xs={6} rowGap={3}>
          <ToggledSettings isLoading={isLoading}>
            <ToggledSetting
              name="skipInstallMobileAppScreen"
              title={t(
                'settingsPage.onboardingTab.settings.skipInstallMobileAppScreenAllowed.title',
              )}
              description={t(
                'settingsPage.onboardingTab.settings.skipInstallMobileAppScreenAllowed.description',
              )}
              checked={skipInstallMobileAppScreen}
              onChange={handleSettingChange}
            />
          </ToggledSettings>
        </Grid>
      </Grid>
    </ScrollablePanelContent>
  );
};

export default OnboardingTab;
