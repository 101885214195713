import { useMemo } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { useUser } from '../../contexts/UserContext';

const ProductTour = () => {
  const { currentUser, ...myvar } = useUser();
  // eslint-disable-next-line no-console
  console.log('currentUser', currentUser);
  // eslint-disable-next-line no-console
  console.log('myvar', myvar);

  const userInfo = useMemo(() => {
    if (!currentUser) {
      return {
        username: 'not logged in',
        email: 'not logged in',
        firstname: 'appUser.firstname',
        lastname: 'appUser.lastname',
        signUpAt: 'appUser.signUpAt',
        role: 'appUser.role',
        props: { customProperty1: 123 },
      };
    }
    return {
      username: currentUser.name,
      email: currentUser.email,
      firstname: 'appUser.firstname',
      lastname: 'appUser.lastname',
      signUpAt: 'appUser.signUpAt',
      role: 'appUser.role',
      props: { customProperty1: 123 },
    };
  }, [currentUser]);

  return (
    <ProductFruits
      workspaceCode="5HTiUbvzycaNR5XL"
      language="en"
      user={userInfo!}
    />
  );
};

export default ProductTour;
