import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import {
  BaseEitherError,
  G25,
  HeroOnboardingWelcomeMessage,
  HeroSplitLayout,
  useSnackbar,
  useTranslations,
  decodeExtensionCommunicationPayload,
  useBooleanState,
} from '@uniqkey-frontend/shared-app';
import { logException } from '../../../../services/sentryService';
import OnboardingModule from '../../services/onboarding';
import { OnboardingHashNotProvidedError } from '../../common/errors';
import CommunicationMessage from './components/CommunicationMessage';
import ErrorMessage from './components/ErrorMessage';

const MAIN_CONTAINER_PROPS = { bgcolor: G25 };

const AutoActivationPage = () => {
  const params = useParams();
  const { t } = useTranslations();
  const { showError } = useSnackbar();

  const { hash } = params;

  const [decoded] = useState(() => decodeExtensionCommunicationPayload(hash));
  const {
    value: isError,
    setValue: setIsError,
  } = useBooleanState(false);

  const handleError = useCallback((error: BaseEitherError) => {
    if (error instanceof OnboardingHashNotProvidedError) return;
    setIsError(true);
    showError({ text: t('common.somethingWentWrong') });
    logException(error, { message: 'AutoActivationPage exception' });
  }, [setIsError, showError, t]);

  useEffect(() => {
    if (isError) {
      return;
    }
    if (OnboardingModule.isInProgress()) { // is used for the development mode
      return;
    }
    const run = async () => {
      const result = await OnboardingModule.start({ decoded, hash });
      if (result instanceof BaseEitherError) {
        handleError(result);
      }
    };
    run();
  }, [decoded, handleError, hash, isError, setIsError, showError, t]);

  const { email, name } = decoded ?? {};

  return (
    <HeroSplitLayout
      LeftContent={(
        <HeroOnboardingWelcomeMessage
          title={t('onboarding.welcome.title', { name: name ?? email })}
          description={t('onboarding.welcome.description')}
        />
      )}
      RightContent={isError ? <ErrorMessage /> : <CommunicationMessage />}
      mainContainerProps={MAIN_CONTAINER_PROPS}
    />
  );
};

export default AutoActivationPage;
