import { AxiosResponse } from 'axios';
import {
  BrowserHelper,
  TExtensionCommunicationResponse,
  isExtensionCommunicationMessageStructureValid,
} from '@uniqkey-frontend/shared-app';
import Operations from '../operations';

const dataExtractor = <T>() => (response: AxiosResponse<T>): T => response.data;

const getSendMessage = () => (
  // @ts-ignore
  window.chrome?.runtime?.sendMessage || window.browser?.runtime?.sendMessage
);

let isInProgress = false;

const setIsInProgress = (value: boolean) => {
  isInProgress = value;
};

const getIsInProgress = () => isInProgress;

const getRequestMethod = () => (
  BrowserHelper.canUseSendMessageAPI()
    ? Operations.sendMessageWithTimeoutOperation
    : Operations.postMessageWithTimeoutOperation
);

const isResponseStructureValid = <TPayload>(
  response: unknown,
) : response is TExtensionCommunicationResponse<TPayload> => (
    isExtensionCommunicationMessageStructureValid<
      TExtensionCommunicationResponse<TPayload>
    >(response)
  );

export default {
  dataExtractor,
  getSendMessage,
  setIsInProgress,
  getIsInProgress,
  getRequestMethod,
  isResponseStructureValid,
};
