import {
  BaseEitherError,
  Either,
  ExtensionCommunicationActionTypeEnum,
  ExtensionCommunicationMessageTypeEnum,
  IExtensionCommunicationTransferDataPayload,
  TExtensionCommunicationDataTransferResponse,
} from '@uniqkey-frontend/shared-app';
import {
  OnboardingTransferDataError,
  OnboardingTransferDataUnhandledError,
  OnboardingTransferDataUnknownError,
  TOnboardingTransferDataOperationErrors,
} from './errors';
import Helpers from '../../helpers';

interface ITransferDataOperationParams {
  extensionId: string;
  hash: string;
}

const EXPECTED_RESPONSE_MESSAGE_TYPES = [
  ExtensionCommunicationMessageTypeEnum.DATA_RECEIVED,
  ExtensionCommunicationMessageTypeEnum.DATA_TRANSFER_ERROR,
];

const transferDataOperation = async (
  params: ITransferDataOperationParams,
): Promise<
  Either<void, TOnboardingTransferDataOperationErrors>
> => {
  try {
    const { extensionId, hash } = params;
    const method = Helpers.getRequestMethod();
    const result = await method<
      IExtensionCommunicationTransferDataPayload,
      TExtensionCommunicationDataTransferResponse
    >({
      extensionId,
      message: {
        messageType: ExtensionCommunicationMessageTypeEnum.TRANSFER_DATA,
        actionType: ExtensionCommunicationActionTypeEnum.AUTO_ACTIVATE,
        payload: {
          hash,
        },
      },
      expectedResponseMessageTypes: EXPECTED_RESPONSE_MESSAGE_TYPES,
    });
    if (result instanceof BaseEitherError) {
      return result;
    }
    switch (result.messageType) {
      case ExtensionCommunicationMessageTypeEnum.DATA_RECEIVED:
        return undefined;
      case ExtensionCommunicationMessageTypeEnum.DATA_TRANSFER_ERROR:
        return new OnboardingTransferDataError();
      case ExtensionCommunicationMessageTypeEnum.UNKNOWN_ERROR:
        return new OnboardingTransferDataUnknownError();
      default:
        return new OnboardingTransferDataUnhandledError();
    }
  } catch (e) {
    return new OnboardingTransferDataUnhandledError();
  }
};

export default transferDataOperation;
