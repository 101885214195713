import axios from 'axios';
import config from '../config';

const EXTENSION_API_BASE_URL = config.getExtensionApiUrl();

const publicAxiosInstance = axios.create({
  baseURL: EXTENSION_API_BASE_URL,
  timeout: config.requestTimeout,
});

export default {
  publicAxiosInstance,
};
