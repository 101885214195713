import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import {
  TOnboardingPostMessageWithTimeoutOperationErrors,
} from '../postMessageWithTimeoutOperation/errors';
import {
  TOnboardingSendMessageWithTimeoutOperationErrors,
} from '../sendMessageWithTimeoutOperation/errors';

export class OnboardingWaitUntilInstalledUnhandledError extends BaseEitherError {}

export type TOnboardingWaitUntilInstalledOperationErrors =
  | TOnboardingSendMessageWithTimeoutOperationErrors
  | TOnboardingPostMessageWithTimeoutOperationErrors
  | OnboardingWaitUntilInstalledUnhandledError;
