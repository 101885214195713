import {
  EmployeeAccountStatus, EmployeeAccountType,
} from '@uniqkey-backend-organization-web/api-client';

export const isSupporter = (role?: EmployeeAccountType) => role === EmployeeAccountType.Supporter;

export const canHaveSecurityScore = (employeeAccountStatus: EmployeeAccountStatus) => (
  employeeAccountStatus === EmployeeAccountStatus.Active
  || employeeAccountStatus === EmployeeAccountStatus.Archived);

export const parseSecurityScore = (securityScore: number) => Math.floor(securityScore);
