import { useCallback, memo } from 'react';
import {
  Grid,
  ScrollablePanelContent,
  ToggledSetting,
  ToggledSettings,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import {
  useGetOrganizationSecuritySettings,
  useGetEmployeeAccountById,
  usePatchEmployeeAccountById,
} from '../../../../hooks/reactQuery';
import {
  createReplacePatchOperation,
  IReplacePatchOperation,
} from '../../../../helpers/apiClients';
import featureConfig from '../../../../featureConfig';
import EmployeePatchOperationEnum from '../../../../enums/EmployeePatchOperationEnum';

interface IEmployeeAccountSettingsTabProps {
  employeeAccountId: string;
}

const EmployeeAccountSettingsTab = (props: IEmployeeAccountSettingsTabProps) => {
  const { employeeAccountId } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();

  const {
    data: organizationSettings,
    // used to show spinner after each time the tab is opened
    isFetchedAfterMount: areOrganizationSettingsFetchedAfterMount,
    isError: isOrganizationSettingsError,
  } = useGetOrganizationSecuritySettings({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const {
    data: employeeAccount,
    // used to show spinner after each time the tab is opened
    isFetchedAfterMount: isEmployeeAccountFetchedAfterMount,
    isError: isEmployeeAccountError,
  } = useGetEmployeeAccountById(
    { employeeAccountId },
  );

  const { mutate, isLoading: isUpdating } = usePatchEmployeeAccountById({
    employeeAccountId,
    useOptimisticUpdates: true,
  });

  const handleSettingChange = useCallback(
    (operations: IReplacePatchOperation[]) => {
      mutate(operations, {
        onError: () => showError({ text: t('common.somethingWentWrong') }),
        onSuccess: () => {
          showSuccess({ text: t('common.settingsUpdatedToast') });
        },
      });
    },
    [mutate, showError, showSuccess, t],
  );

  const handleAllowPhonelessChange = useCallback((name: string, checked: boolean) => {
    if (isUpdating) {
      return;
    }
    handleSettingChange([
      createReplacePatchOperation(EmployeePatchOperationEnum.PhonelessAllowed, checked),
    ]);
  }, [isUpdating, handleSettingChange]);

  if (isEmployeeAccountError || isOrganizationSettingsError) {
    return null;
  }

  const {
    phonelessAllowed,
  } = employeeAccount ?? {};
  const {
    phonelessAllowed: organizationPhonelessAllowed,
  } = organizationSettings ?? {};

  const phonelessAllowedTooltip = !organizationPhonelessAllowed
    ? t('employeePage.settingsTab.tooltips.phonelessDisabledInOrganization')
    : undefined;

  return (
    <ScrollablePanelContent p={3} pt={1}>
      <Grid container spacing={2} className="height-100-percent">
        <Grid container item xs={6} flexDirection="column">
          <ToggledSettings
            isLoading={
              !isEmployeeAccountFetchedAfterMount && !areOrganizationSettingsFetchedAfterMount
            }
          >
            {featureConfig.isPhonelessEnabled && (
              <ToggledSetting
                name="phonelessAllowed"
                title={t('employeePage.settingsTab.settings.allowPhoneless.title')}
                description={t('employeePage.settingsTab.settings.allowPhoneless.description')}
                checked={!!organizationPhonelessAllowed && !!phonelessAllowed}
                disabled={!organizationPhonelessAllowed}
                onChange={handleAllowPhonelessChange}
                tooltip={phonelessAllowedTooltip}
              />
            )}
          </ToggledSettings>
        </Grid>
      </Grid>
    </ScrollablePanelContent>
  );
};

export default memo(EmployeeAccountSettingsTab);
