import { useCallback } from 'react';
import {
  Grid,
  ScrollablePanelContent,
  ToggledSetting,
  ToggledSettings,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import {
  useGetOrganizationSecuritySettings,
  useUpdateOrganizationSecuritySettings,
} from '../../../../hooks/reactQuery';
import { useUser } from '../../../../contexts/UserContext';
import featureConfig from '../../../../featureConfig';

const SecurityTab = () => {
  const { t } = useTranslations();

  const { isSupporter } = useUser();
  const { showError, showSuccess } = useSnackbar();
  const {
    data: settings, isLoading, isError,
  } = useGetOrganizationSecuritySettings({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });
  const {
    mutate,
    isLoading: isUpdating,
  } = useUpdateOrganizationSecuritySettings({ useOptimisticUpdates: true });

  const handleSettingChange = useCallback((name: string, checked: boolean) => {
    if (isUpdating) {
      return;
    }
    mutate(
      { ...settings!, [name]: checked },
      {
        onError: () => showError({ text: t('common.somethingWentWrong') }),
        onSuccess: () => {
          showSuccess({ text: t('common.settingsUpdatedToast') });
        },
      },
    );
  }, [isUpdating, mutate, settings, showError, showSuccess, t]);

  if (isError) {
    return null;
  }

  const {
    trustedBrowserAllowed,
    exportAllowed,
    autoSaveAllowed,
    secondaryDeviceAllowed,
    trustedPortalAllowed,
    phonelessAllowed,
    enforce2FAVerification,
    sharingDisabled,
    allowScreenshots,
  } = settings ?? {};

  return (
    <ScrollablePanelContent p={3}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid container item xs={6} rowGap={3}>
          <ToggledSettings isLoading={isLoading}>
            <ToggledSetting
              name="trustedBrowserAllowed"
              title={t('settingsPage.securityTab.settings.trustedBrowserAllowed.title')}
              description={t('settingsPage.securityTab.settings.trustedBrowserAllowed.description')}
              checked={trustedBrowserAllowed}
              onChange={handleSettingChange}
            />
          </ToggledSettings>
          <ToggledSettings isLoading={isLoading}>
            <ToggledSetting
              name="secondaryDeviceAllowed"
              title={t('settingsPage.securityTab.settings.secondaryDeviceAllowed.title')}
              description={
                t('settingsPage.securityTab.settings.secondaryDeviceAllowed.description')
              }
              checked={secondaryDeviceAllowed}
              onChange={handleSettingChange}
            />
          </ToggledSettings>
          <ToggledSettings isLoading={isLoading}>
            <ToggledSetting
              name="trustedPortalAllowed"
              title={t('settingsPage.securityTab.settings.trustedPortalAllowed.title')}
              description={t('settingsPage.securityTab.settings.trustedPortalAllowed.description')}
              checked={trustedPortalAllowed}
              onChange={handleSettingChange}
              disabled={isSupporter}
            />
          </ToggledSettings>
          {featureConfig.isPhonelessEnabled && (
            <ToggledSettings isLoading={isLoading}>
              <ToggledSetting
                name="phonelessAllowed"
                title={t('settingsPage.securityTab.settings.phonelessAllowed.title')}
                description={t('settingsPage.securityTab.settings.phonelessAllowed.description')}
                checked={phonelessAllowed}
                onChange={handleSettingChange}
              />
            </ToggledSettings>
          )}
        </Grid>
        <Grid container item xs={6} rowGap={3}>
          <ToggledSettings isLoading={isLoading}>
            <ToggledSetting
              name="exportAllowed"
              title={t('settingsPage.securityTab.settings.exportAllowed.title')}
              description={t('settingsPage.securityTab.settings.exportAllowed.description')}
              checked={exportAllowed}
              onChange={handleSettingChange}
            />
          </ToggledSettings>
          <ToggledSettings isLoading={isLoading}>
            <ToggledSetting
              name="autoSaveAllowed"
              title={t('settingsPage.securityTab.settings.autoSaveAllowed.title')}
              description={t('settingsPage.securityTab.settings.autoSaveAllowed.description')}
              checked={autoSaveAllowed}
              onChange={handleSettingChange}
            />
          </ToggledSettings>
          <ToggledSettings isLoading={isLoading}>
            <ToggledSetting
              name="enforce2FAVerification"
              title={t('settingsPage.securityTab.settings.enforce2FAVerification.title')}
              description={
                t('settingsPage.securityTab.settings.enforce2FAVerification.description')
              }
              checked={enforce2FAVerification}
              onChange={handleSettingChange}
            />
          </ToggledSettings>
          <ToggledSettings isLoading={isLoading}>
            <ToggledSetting
              name="sharingDisabled"
              title={t('settingsPage.securityTab.settings.sharingDisabled.title')}
              description={t('settingsPage.securityTab.settings.sharingDisabled.description')}
              checked={sharingDisabled}
              onChange={handleSettingChange}
            />
          </ToggledSettings>
          {featureConfig.securityOverlayEnabled && (
            <ToggledSettings isLoading={isLoading}>
              <ToggledSetting
                name="allowScreenshots"
                title={t('settingsPage.securityTab.settings.allowScreenshots.title')}
                description={t('settingsPage.securityTab.settings.allowScreenshots.description')}
                checked={allowScreenshots}
                onChange={handleSettingChange}
              />
            </ToggledSettings>
          )}
        </Grid>
      </Grid>
    </ScrollablePanelContent>
  );
};

export default SecurityTab;
