import { useCallback, memo, SyntheticEvent } from 'react';
import classNames from 'classnames';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations,
} from '@uniqkey-frontend/shared-app';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentOrganization } from '../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import OrganizationDetailsTab from '../OrganizationDetailsTab';
import SecurityTab from '../SecurityTab';
import SCIMIntegrationTab from '../SCIMIntegrationTab';
import VerifiedDomainsTab from '../VerifiedDomainsTab';
import PartnerUsersWithAccessTab from '../PartnerUsersWithAccessTab';
import PartnersWithAccessTab from '../PartnersWithAccessTab';
import RetentionPeriodTab from '../RetentionPeriodTab';
import featureConfig from '../../../../featureConfig';
import OnboardingTab from '../OnboardingTab';

export enum SettingsModuleTabEnum {
  Details = 'details',
  Security = 'security',
  SCIMIntegration = 'scim-integration',
  VerifiedDomains = 'verified-domains',
  PartnerUsersWithAccess = 'partner-users',
  PartnersWithAccess = 'partners',
  RetentionPeriod = 'retention-period',
  Onboarding = 'onboarding',
}
interface ISettingsModuleProps {
  activeTab: SettingsModuleTabEnum;
}

const SettingsModule = (props: ISettingsModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();

  const { isLoading } = useGetCurrentOrganization();

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: SettingsModuleTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.Settings}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [activeTab, navigate]);

  return (
    <Panel
      className={classNames('flex-column', {
        'height-100-percent': activeTab !== SettingsModuleTabEnum.Details,
      })}
    >
      <PanelHeader px={3}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('settingsPage.tabs.organizationDetails')}
            value={SettingsModuleTabEnum.Details}
            onClick={handleTabClick}
          />
          <Tab
            label={t('settingsPage.tabs.security')}
            value={SettingsModuleTabEnum.Security}
            onClick={handleTabClick}
          />
          <Tab
            label={t('settingsPage.tabs.scimIntegration')}
            value={SettingsModuleTabEnum.SCIMIntegration}
            onClick={handleTabClick}
          />
          <Tab
            label={t('settingsPage.tabs.verifiedDomains')}
            value={SettingsModuleTabEnum.VerifiedDomains}
            onClick={handleTabClick}
          />
          {featureConfig.isNewOnboardingEnabled && (
            <Tab
              label={t('settingsPage.tabs.onboarding')}
              value={SettingsModuleTabEnum.Onboarding}
              onClick={handleTabClick}
            />
          )}
          <Tab
            label={t('settingsPage.tabs.partnerUsersWithAccess')}
            value={SettingsModuleTabEnum.PartnerUsersWithAccess}
            onClick={handleTabClick}
          />
          <Tab
            label={t('settingsPage.tabs.partnersWithAccess')}
            value={SettingsModuleTabEnum.PartnersWithAccess}
            onClick={handleTabClick}
          />
          {featureConfig.restoreDataEnabled && (
            <Tab
              label={t('settingsPage.tabs.retentionPeriod')}
              value={SettingsModuleTabEnum.RetentionPeriod}
              onClick={handleTabClick}
            />
          )}
        </Tabs>
      </PanelHeader>
      {activeTab === SettingsModuleTabEnum.Details && <OrganizationDetailsTab />}
      {activeTab === SettingsModuleTabEnum.Security && <SecurityTab />}
      {activeTab === SettingsModuleTabEnum.SCIMIntegration && <SCIMIntegrationTab />}
      {activeTab === SettingsModuleTabEnum.VerifiedDomains && <VerifiedDomainsTab />}
      {activeTab === SettingsModuleTabEnum.PartnerUsersWithAccess && <PartnerUsersWithAccessTab />}
      {activeTab === SettingsModuleTabEnum.PartnersWithAccess && <PartnersWithAccessTab />}
      {((activeTab === SettingsModuleTabEnum.RetentionPeriod && featureConfig.restoreDataEnabled)
        && <RetentionPeriodTab />
      )}
      {((activeTab === SettingsModuleTabEnum.Onboarding && featureConfig.isNewOnboardingEnabled)
        && <OnboardingTab />
      )}
    </Panel>
  );
};

export default memo(SettingsModule);
