import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import { TOnboardingLoginStepOneOperationErrors } from '../loginStepOneOperation/errors';
import { TOnboardingLoginStepTwoOperationErrors } from '../loginStepTwoOperation/errors';

export class OnboardingVerificationError extends BaseEitherError {}

export type TOnboardingVerifyCredentialsOperationErrors =
  | OnboardingVerificationError
  | TOnboardingLoginStepOneOperationErrors
  | TOnboardingLoginStepTwoOperationErrors;
