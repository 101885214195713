import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import { useSnackbar } from '@uniqkey-frontend/shared-app';
import OnboardingPageRouteEnum from './enums/OnboardingPageRouteEnum';
import AutoActivationPage from './pages/AutoActivationPage';

const Router = () => {
  const { getSnackbar } = useSnackbar();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={OnboardingPageRouteEnum.AutoActivation}
          element={<AutoActivationPage />}
        />
        <Route
          path="*"
          element={<Navigate to={OnboardingPageRouteEnum.AutoActivation} />}
        />
      </Routes>
      {getSnackbar()}
    </BrowserRouter>
  );
};

export default Router;
