import type { Either } from '@uniqkey-frontend/shared-app';
import {
  UserLoginStepTwoResponse,
  UserLoginStepTwoRequest,
} from '@uniqkey-backend-organization-extension/api-client';
import {
  OnboardingLoginStepTwoError,
  type TOnboardingLoginStepTwoOperationErrors,
} from './errors';
import APIClientsProvider from '../../apiClientsProvider';
import Helpers from '../../helpers';
import Axios from '../../axios';

interface ILoginStepTwoOperationParams extends UserLoginStepTwoRequest {}

const loginStepTwoOperation = async (
  params: ILoginStepTwoOperationParams,
): Promise<Either<UserLoginStepTwoResponse, TOnboardingLoginStepTwoOperationErrors>> => {
  try {
    const {
      userId, email, clientEphemeralPublic, clientSessionProof,
    } = params;

    const result = await APIClientsProvider
      .getUserAPIClient(Axios.publicAxiosInstance)
      .apiV1UserLoginStepTwoPost({
        userId,
        email,
        clientEphemeralPublic,
        clientSessionProof,
      })
      .then(Helpers.dataExtractor<UserLoginStepTwoResponse>());
    return result;
  } catch (e) {
    return new OnboardingLoginStepTwoError();
  }
};

export default loginStepTwoOperation;
