enum ReactQueryKeyEnum {
  SupportedLanguages = 'supportedLanguages',
  CurrentUser = 'currentUser',
  CurrentEmployee = 'currentEmployee',
  Employees = 'employees',
  Employee = 'employee',
  EmployeeSecurityScore = 'employeeSecurityScore',
  Groups = 'groups',
  Group = 'group',
  GroupRestrictions = 'groupRestrictions',
  OrganizationLoginEmployeesToEmployees = 'organizationLoginEmployeesToEmployees',
  OrganizationLoginEmployeesToGroups = 'organizationLoginEmployeesToGroups',
  OrganizationLoginGroups = 'organizationLoginGroups',
  Organization = 'organization',
  OrganizationSecuritySettings = 'organizationSecuritySettings',
  OrganizationOnboardingSettings = 'organizationOnboardingSettings',
  OrganizationRetentionPeriod = 'organizationRetentionPeriod',
  OrganizationScimSettings = 'organizationScimSettings',
  Logins = 'logins',
  Login = 'login',
  CreditCards = 'creditCards',
  SecureNotes = 'secureNotes',
  Services = 'services',
  Service = 'service',
  ServiceEmployees = 'serviceEmployees',
  ServicesByApplicationId = 'servicesByApplicationId',
  DashboardEmployeeAccountsOnBoard = 'dashboardEmployeeAccountsOnBoard',
  DashboardEmployeeActivationRate = 'dashboardEmployeeActivationRate',
  DashboardActiveEmployeesRate = 'dashboardActiveEmployeesRate',
  DashboardAverageSecurityScore = 'dashboardAverageSecurityScore',
  DashboardSecurityScoreRate = 'dashboardSecurityScoreRate',
  DashboardLowSecurityScoreGroups = 'dashboardLowSecurityScoreGroups',
  UnprocessedInfo = 'unprocessedInfo',
  UnprocessedSCIMUsersCount = 'unprocessedSCIMUsersCount',
  AccountRecoveryRequests = 'accountRecoveryRequests',
  AuditLogs = 'auditLogs',
  PartnerAccessRequests = 'partnerAccessRequests',
  PartnersWithAccess = 'partnersWithAccess',
  PartnerUsersWithAccess = 'partnerUsersWithAccess',
  VerifiedDomains = 'verifiedDomains',
}

export default ReactQueryKeyEnum;
