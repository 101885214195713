import { ServiceModuleTabEnum } from '../../pages/ServicePage/components/ServiceModule';

enum PageRouteEnum {
  Login = '/login',
  Dashboard = '/dashboard',
  Employees = '/employees',
  Employee = `${PageRouteEnum.Employees}/:employeeAccountId/*`,
  Groups = '/groups',
  Group = `${PageRouteEnum.Groups}/:groupId/*`,
  Reports = '/reports',
  Emails = '/emails',
  Services = '/services',
  Service = `${PageRouteEnum.Services}/:applicationId/*`,
  ServiceLoginsTab = `${PageRouteEnum.Services}/:applicationId/${ServiceModuleTabEnum.Logins}`,
  SecureData = '/secure-data',
  Settings = '/settings',
  Profile = '/profile',
  AllSecuredData = '/all-vaults',
  OrganizationLogin = `${PageRouteEnum.AllSecuredData}/logins/:vaultId/*`,
  Requests = '/requests',
  Audit = '/audit',
  SupportJump = '/support/jump/:organizationId?/:token?',
}

export default PageRouteEnum;
