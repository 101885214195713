/* eslint-disable max-classes-per-file */
import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import {
  TOnboardingVerifyCredentialsOperationErrors,
} from '../operations/verifyCredentialsOperation/errors';
import {
  TOnboardingInitialExtensionCheckOperationErrors,
} from '../operations/initialExtensionCheckOperation/errors';
import { TOnboardingTransferDataOperationErrors } from '../operations/transferDataOperation/errors';

export class OnboardingHashNotProvidedError extends BaseEitherError {}

export class OnboardingHashNotDecodedError extends BaseEitherError {}

export class OnboardingUnknownError extends BaseEitherError {}

export type TOnboardingErrors = OnboardingUnknownError
  | OnboardingHashNotProvidedError
  | OnboardingHashNotDecodedError
  | TOnboardingVerifyCredentialsOperationErrors
  | TOnboardingInitialExtensionCheckOperationErrors
  | TOnboardingTransferDataOperationErrors;
