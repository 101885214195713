import { Suspense } from 'react';
import Router from './Router';

const App = () => (
  <Suspense fallback={null}>
    <Router />
  </Suspense>
);

export default App;
