import { BaseEitherError } from '@uniqkey-frontend/shared-app';

export class OnboardingSendMessageTimeoutError extends BaseEitherError {}
export class OnboardingSendMessageSendError extends BaseEitherError {}
export class OnboardingSendMessageMalformedResponseError extends BaseEitherError {}
export class OnboardingSendMessageUnexpectedResponseTypeError extends BaseEitherError {}
export class OnboardingSendMessageNotAvailableError extends BaseEitherError {}

export type TOnboardingSendMessageWithTimeoutOperationErrors =
  | OnboardingSendMessageTimeoutError
  | OnboardingSendMessageSendError
  | OnboardingSendMessageMalformedResponseError
  | OnboardingSendMessageUnexpectedResponseTypeError
  | OnboardingSendMessageNotAvailableError;
