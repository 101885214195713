import verifyCredentialsOperation from './verifyCredentialsOperation';
import sendMessageWithTimeoutOperation from './sendMessageWithTimeoutOperation';
import postMessageWithTimeoutOperation from './postMessageWithTimeoutOperation';
import initialExtensionCheckOperation from './initialExtensionCheckOperation';
import waitUntilInstalledOperation from './waitUntilInstalledOperation';
import transferDataOperation from './transferDataOperation';

export default {
  verifyCredentialsOperation,
  sendMessageWithTimeoutOperation,
  postMessageWithTimeoutOperation,
  initialExtensionCheckOperation,
  waitUntilInstalledOperation,
  transferDataOperation,
};
