import {
  derivePrivateKey, deriveSession, generateEphemeral, verifySession,
} from 'secure-remote-password/client';
import {
  BaseEitherError,
  Either,
  IExtensionCommunicationDecodedPayload,
} from '@uniqkey-frontend/shared-app';
import {
  OnboardingVerificationError,
  type TOnboardingVerifyCredentialsOperationErrors,
} from './errors';
import loginStepOneOperation from '../loginStepOneOperation';
import loginStepTwoOperation from '../loginStepTwoOperation';

interface IVerifyCredentialsOperationParams {
  email: IExtensionCommunicationDecodedPayload['email'];
  code: IExtensionCommunicationDecodedPayload['code'];
}

const verifyCredentialsOperation = async (
  params: IVerifyCredentialsOperationParams,
): Promise<Either<void, TOnboardingVerifyCredentialsOperationErrors>> => {
  try {
    const { email, code } = params;

    const loginStepOneResult = await loginStepOneOperation({
      email,
    });
    if (loginStepOneResult instanceof BaseEitherError) {
      return loginStepOneResult;
    }

    const {
      userId,
      salt,
      serverEphemeralPublic,
    } = loginStepOneResult;

    const clientEphemeral = generateEphemeral();
    const privateKey = derivePrivateKey(salt, userId, code);
    const clientSession = deriveSession(
      clientEphemeral.secret,
      serverEphemeralPublic,
      salt,
      userId,
      privateKey,
    );

    const loginStepTwoResult = await loginStepTwoOperation({
      userId,
      email,
      clientEphemeralPublic: clientEphemeral.public,
      clientSessionProof: clientSession.proof,
    });
    if (loginStepTwoResult instanceof BaseEitherError) {
      return loginStepTwoResult;
    }

    const { serverSessionProof } = loginStepTwoResult;

    verifySession(clientEphemeral.public, clientSession, serverSessionProof);

    return undefined;
  } catch (error) {
    return new OnboardingVerificationError();
  }
};

export default verifyCredentialsOperation;
