import type { AxiosInstance } from 'axios';
import { UserApi } from '@uniqkey-backend-organization-extension/api-client';
import config from '../config';

const EXTENSION_API_BASE_URL = config.getExtensionApiUrl();

export const getUserAPIClient = (instance: AxiosInstance) => new UserApi(
  undefined,
  EXTENSION_API_BASE_URL,
  instance,
);

export default {
  getUserAPIClient,
};
