import {
  generateGUID,
  Either,
  TimeEnum,
  TExtensionCommunicationRequest,
  TExtensionCommunicationResponse,
  ExtensionCommunicationMessageTypeEnum,
} from '@uniqkey-frontend/shared-app';
import {
  OnboardingPostMessageHandlingError,
  OnboardingPostMessageUnexpectedResponseTypeError,
  OnboardingPostMessagePostError,
  OnboardingPostMessageTimeoutError,
  TOnboardingPostMessageWithTimeoutOperationErrors,
} from './errors';
import Helpers from '../../helpers';

export interface IPostMessageWithTimeoutOperationParams<TPayload> {
  extensionId: string;
  message: TExtensionCommunicationRequest<TPayload>;
  timeout?: TimeEnum;
  expectedResponseMessageTypes?: ExtensionCommunicationMessageTypeEnum[];
}

const postMessageWithTimeoutOperation = <
  TRequestPayload, TResponsePayload
>(params: IPostMessageWithTimeoutOperationParams<TRequestPayload>): Promise<
  Either<
    TExtensionCommunicationResponse<TResponsePayload>,
    TOnboardingPostMessageWithTimeoutOperationErrors
  >
> => new Promise((resolve) => {
    const {
      extensionId, message: preMessage,
      timeout = TimeEnum.OneSecond,
      expectedResponseMessageTypes = [],
    } = params;

    const expectedResponseMessageTypesAsSet = new Set([
      ...expectedResponseMessageTypes,
      ExtensionCommunicationMessageTypeEnum.UNKNOWN_ERROR,
    ]);

    const messageId = preMessage.messageId ?? generateGUID();
    const message = { ...preMessage, messageId };

    const messageHandler = (event: MessageEvent) => {
      try {
        if (event.origin !== window.location.origin || event.source !== window) return;

        const { data } = event;
        if (
          !Helpers.isResponseStructureValid<TResponsePayload>(data)
          || !data.replyToId
          || !data.extensionId
        ) return;

        if (data.extensionId !== extensionId) return;

        if (data.replyToId !== messageId) return;

        if (!expectedResponseMessageTypesAsSet.has(data.messageType)) {
          clearTimeout(timeoutId);
          window.removeEventListener('message', messageHandler);
          resolve(new OnboardingPostMessageUnexpectedResponseTypeError());
          return;
        }

        clearTimeout(timeoutId);
        window.removeEventListener('message', messageHandler);
        resolve(data);
      } catch (error) {
        clearTimeout(timeoutId);
        window.removeEventListener('message', messageHandler);
        resolve(new OnboardingPostMessageHandlingError());
      }
    };

    const timeoutId = setTimeout(() => {
      window.removeEventListener('message', messageHandler);
      resolve(new OnboardingPostMessageTimeoutError());
    }, timeout);

    window.addEventListener('message', messageHandler);

    try {
      window.postMessage(message, window.location.origin);
    } catch (error) {
      clearTimeout(timeoutId);
      window.removeEventListener('message', messageHandler);
      resolve(new OnboardingPostMessagePostError());
    }
  });

export default postMessageWithTimeoutOperation;
