import { ChangeEvent, memo, useEffect } from 'react';
import {
  Button,
  DialogActions,
  MenuItem,
  Select,
  Typography,
  useTranslations,
  Grid,
  TSelectProps,
  Checkbox,
  Tooltip,
  useSnackbar,
} from '@uniqkey-frontend/shared-app';

import InviteEmployeesRadioGroup from '../InviteEmployeesRadioGroup';
import {
  useLanguages,
  useGetOrganizationSecuritySettings,
} from '../../../../../../hooks/reactQuery';

interface IInviteEmployeesDialogActionsProps {
  isDisabled: boolean;
  onLanguageChange: TSelectProps['onChange'];
  language: string;
  onSubmit: () => void;
  isLoading: boolean;
  onRadioGroupChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPhonelessAllowedCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
  phonelessAllowed: boolean;
  onClose: () => void;
}

const InviteEmployeesDialogActions = (props: IInviteEmployeesDialogActionsProps) => {
  const {
    onLanguageChange,
    language,
    onSubmit,
    isDisabled,
    isLoading,
    onRadioGroupChange,
    onPhonelessAllowedCheckboxChange,
    phonelessAllowed,
    onClose,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();

  const {
    data: organizationSettings,
    isLoading: areOrganizationSettingsLoading,
    isError: isOrganizationSettingsError,
  } = useGetOrganizationSecuritySettings({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const { languages, isLoading: areLanguagesLoading } = useLanguages({
    includeOnlyAllowedLanguages: false,
  });

  const {
    phonelessAllowed: organizationPhonelessAllowed,
  } = organizationSettings ?? {};

  const phonelessAllowedTooltip = !organizationPhonelessAllowed
    ? t('inviteEmployeesModal.phoneless.checkbox.tooltip')
    : undefined;

  useEffect(() => {
    if (!isOrganizationSettingsError) return;
    onClose();
  }, [isOrganizationSettingsError, onClose]);

  return (
    <DialogActions paddingTop={1}>
      <Grid container>
        <Grid item xs={12} mb={1}>
          <InviteEmployeesRadioGroup onRadioGroupChange={onRadioGroupChange} />
        </Grid>
        {!areOrganizationSettingsLoading && (
          <Grid item xs={12} ml="-10px">
            <Tooltip title={phonelessAllowedTooltip} followCursor>
              <Checkbox
                label={t('inviteEmployeesModal.phoneless.checkbox.label')}
                checked={phonelessAllowed}
                onChange={onPhonelessAllowedCheckboxChange}
                disabled={!organizationPhonelessAllowed}
              />
            </Tooltip>
          </Grid>
        )}
        <Grid container mt={1} spacing={2} alignItems="center">
          <Grid item xs={4} textAlign="end">
            <Typography color="primary">
              {t('inviteEmployeesModal.langSelectorLabel')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {
              !areLanguagesLoading && (
                <Select
                  fullWidth
                  value={language}
                  label={t('inviteEmployeesModal.selectLang')}
                  onChange={onLanguageChange}
                >
                  {
                    languages.map((currLanguage) => (
                      <MenuItem key={currLanguage.code} value={currLanguage.code}>
                        {currLanguage.localized_name}
                      </MenuItem>
                    ))
                  }
                </Select>
              )
            }
          </Grid>
          <Grid item xs={4}>
            <Button
              disabled={isDisabled}
              type="submit"
              onClick={onSubmit}
              isLoading={isLoading}
              fullWidth
            >
              {t('common.add')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DialogActions>
  );
};

export default memo(InviteEmployeesDialogActions);
