import {
  Either,
  ExtensionCommunicationMessageTypeEnum,
  OmitFromUnion,
  TExtensionCommunicationRequest,
  TExtensionCommunicationResponse,
  TimeEnum,
} from '@uniqkey-frontend/shared-app';
import {
  OnboardingSendMessageUnexpectedResponseTypeError,
  OnboardingSendMessageMalformedResponseError,
  OnboardingSendMessageNotAvailableError,
  OnboardingSendMessageSendError,
  OnboardingSendMessageTimeoutError,
  TOnboardingSendMessageWithTimeoutOperationErrors,
} from './errors';
import Helpers from '../../helpers';

export interface ISendMessageWithTimeoutOperationParams<TPayload> {
  extensionId: string;
  message: OmitFromUnion<TExtensionCommunicationRequest<TPayload>, 'messageId'>;
  timeout?: TimeEnum;
  expectedResponseMessageTypes?: ExtensionCommunicationMessageTypeEnum[];
}

const sendMessageWithTimeoutOperation = <
  TRequestPayload, TResponsePayload
>(params: ISendMessageWithTimeoutOperationParams<TRequestPayload>): Promise<
  Either<
    TExtensionCommunicationResponse<TResponsePayload>,
    TOnboardingSendMessageWithTimeoutOperationErrors
  >
> => new Promise((resolve) => {
    const sendMessage = Helpers.getSendMessage();
    if (!sendMessage) {
      resolve(new OnboardingSendMessageNotAvailableError());
      return;
    }

    const {
      extensionId,
      message,
      timeout = TimeEnum.ThreeSeconds,
      expectedResponseMessageTypes = [],
    } = params;

    const expectedResponseMessageTypesAsSet = new Set([
      ...expectedResponseMessageTypes,
      ExtensionCommunicationMessageTypeEnum.UNKNOWN_ERROR,
    ]);

    const timeoutId = setTimeout(() => {
      resolve(new OnboardingSendMessageTimeoutError());
    }, timeout);

    (async () => {
      try {
        const response = await sendMessage(extensionId, message);
        clearTimeout(timeoutId);
        if (!Helpers.isResponseStructureValid<TResponsePayload>(response)) {
          resolve(new OnboardingSendMessageMalformedResponseError());
          return;
        }
        if (!expectedResponseMessageTypesAsSet.has(response.messageType)) {
          resolve(new OnboardingSendMessageUnexpectedResponseTypeError());
          return;
        }
        resolve(response);
      } catch (error) {
        clearTimeout(timeoutId);
        resolve(new OnboardingSendMessageSendError());
      }
    })();
  });

export default sendMessageWithTimeoutOperation;
