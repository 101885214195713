import { BaseEitherError } from '@uniqkey-frontend/shared-app';

export class OnboardingPostMessageTimeoutError extends BaseEitherError {}
export class OnboardingPostMessagePostError extends BaseEitherError {}
export class OnboardingPostMessageUnexpectedResponseTypeError extends BaseEitherError {}
export class OnboardingPostMessageHandlingError extends BaseEitherError {}

export type TOnboardingPostMessageWithTimeoutOperationErrors =
  | OnboardingPostMessageTimeoutError
  | OnboardingPostMessagePostError
  | OnboardingPostMessageUnexpectedResponseTypeError
  | OnboardingPostMessageHandlingError;
