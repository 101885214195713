import {
  memo, useCallback, useMemo, useState, useEffect,
} from 'react';
import {
  AG1,
  AG2,
  S4,
  AR1,
  Box,
  EditableContainer,
  EntityDetailsContainer,
  Grid,
  Label,
  S5,
  Switch,
  Typography,
  useSnackbar,
  useTranslations,
  formatDate,
  Spinner,
  TypographyWithTooltip,
} from '@uniqkey-frontend/shared-app';
import {
  EmployeeAccountType,
  EmployeeAccountStatus,
  GetEmployeeAccountByIdResponse,
} from '@uniqkey-backend-organization-web/api-client';
import {
  useGetEmployeeAccountById,
  usePatchEmployeeAccountById,
  useChangeAdminRights,
  useGetOrganizationScimSettings,
} from '../../../../hooks/reactQuery';
import EditEmployeeModal, { IEditEmployeeModalReturnValue } from '../EditEmployeeModal';
import { createReplacePatchOperation } from '../../../../helpers/apiClients';
import { EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS } from '../../../../constants';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';
import RealtimeAPIEventTypeEnum from '../../../../enums/RealtimeAPIEventTypeEnum';
import { logException } from '../../../../services/sentryService';
import QueueEventEnum from '../../../../enums/QueueEventEnum';
import useMobileRequestOverlay from '../../../../hooks/useMobileRequestOverlay';
import MobileRequestOverlay from '../../../../components/MobileRequestOverlay';
import { listenMobileAction } from '../../../../helpers/webSockets';
import EmployeePatchOperationEnum from '../../../../enums/EmployeePatchOperationEnum';

interface IEmployeeDetailsWidgetProps {
  employeeAccountId: string;
}

const STATUS_COLORS = {
  [EmployeeAccountStatus.Active]: AG2,
  [EmployeeAccountStatus.Invited]: S5,
  [EmployeeAccountStatus.Archived]: AR1,
  [EmployeeAccountStatus.Unprocessed]: S4,
  [EmployeeAccountStatus.Staged]: S5,
  [EmployeeAccountStatus.MigrationInvited]: S5,
  [EmployeeAccountStatus.Migrated]: S5,
  [EmployeeAccountStatus.ExistingUnprocessed]: S4,
};

const YOU_CANNOT_REVOKE_YOURSELF_ERROR = 'You_cannot_revoke_yourself';

const EmployeeDetailsWidget = (props: IEmployeeDetailsWidgetProps) => {
  const { employeeAccountId } = props;
  const { t } = useTranslations();
  const [isEditEmployeeModalOpen, setIsEditEmployeeModalOpen] = useState(false);
  const [isEmployeeEditLoading, setIsEmployeeEditLoading] = useState(false);
  const [isAdminLoading, setIsAdminLoading] = useState(false);
  const handleEditEmployeeModalClose = useCallback(() => setIsEditEmployeeModalOpen(false), []);
  const handleEditEmployeeModalOpen = useCallback(() => setIsEditEmployeeModalOpen(true), []);
  const handleTimeout = useCallback(() => setIsAdminLoading(false), []);
  const {
    isMobileRequestOverlayOpen,
    mobileRequestOverlayQueueMessageId,
    openMobileRequestOverlay,
    closeMobileRequestOverlay,
    clearMobileRequestOverlayTimeout,
  } = useMobileRequestOverlay({ onTimeout: handleTimeout });

  const [
    trustedPortalQueueMessageId,
    setTrustedPortalQueueMessageId,
  ] = useState<string | null>(null);

  const {
    data: employeeAccount,
    isLoading: isGetEmployeeAccountByIdLoading,
    isError: isGetEmployeeAccountByIdError,
  } = useGetEmployeeAccountById(
    { employeeAccountId },
  );

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const {
    data: scim,
    isLoading: isGetOrganizationScimSettingsLoading,
    isError: isGetOrganizationScimSettingsError,
  } = useGetOrganizationScimSettings({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const {
    name,
    email,
    employeeAccountType,
    activityAt,
    externalCreationId,
    isLastAdmin,
    employeeAccountStatus,
  } = employeeAccount ?? {} as GetEmployeeAccountByIdResponse;
  const isAdmin = employeeAccountType === EmployeeAccountType.Admin;
  const isSwitchDisabled = employeeAccountStatus !== EmployeeAccountStatus.Active || isLastAdmin;
  const lastActivity = useMemo(() => formatDate({ date: activityAt }), [activityAt]);
  const { showError, showSuccess } = useSnackbar();
  const { mutate: mutateEmployee } = usePatchEmployeeAccountById({
    employeeAccountId,
    useOptimisticUpdates: true,
  });
  const { mutateAsync: mutateAdmin, isLoading: isAdminUpdating } = useChangeAdminRights({
    employeeAccountId,
    promote: !isAdmin,
    useOptimisticUpdates: true,
  });

  const handleAdminRightsChange = useCallback(async () => {
    try {
      if (isAdminUpdating) {
        return;
      }
      setIsAdminLoading(true);
      if (!isTrustedPortalEnabled) {
        clearMobileRequestOverlayTimeout();
      }
      const { queueMessageId: id } = await mutateAdmin();
      if (!isTrustedPortalEnabled) {
        openMobileRequestOverlay(id);
      } else {
        setTrustedPortalQueueMessageId(id);
      }
    } catch (e: any) {
      setIsAdminLoading(false);
      let key = 'common.somethingWentWrong';
      if (e?.response?.data?.includes(YOU_CANNOT_REVOKE_YOURSELF_ERROR)) {
        key = 'employeeDetailsWidget.youCannotRevokeYourselfError';
      }
      showError({ text: t(key) });
      logException(e, {
        message: 'EmployeeDetailsWidget/handleAdminRightsChange exception',
      });
    }
  }, [
    openMobileRequestOverlay,
    clearMobileRequestOverlayTimeout,
    isAdminUpdating,
    mutateAdmin,
    isTrustedPortalEnabled,
    showError,
    t,
  ]);

  useEffect(() => {
    const queueMessageId = mobileRequestOverlayQueueMessageId || trustedPortalQueueMessageId;
    if (!queueMessageId) {
      return undefined;
    }
    const unsubscribe = listenMobileAction(
      RealtimeAPIEventTypeEnum.QueueMessageResultNotification,
      queueMessageId,
      QueueEventEnum.promoteAdmin,
      async (event) => {
        try {
          const { status } = event;
          if (!isTrustedPortalEnabled) {
            closeMobileRequestOverlay();
          } else {
            setTrustedPortalQueueMessageId(null);
          }
          if (status) {
            showError({ text: t('mobileRequestNotifications.promoteAdminRejected') });
          } else {
            showSuccess({ text: t('mobileRequestNotifications.promoteAdminApproved') });
          }
          setIsAdminLoading(false);
        } catch (e) {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, {
            message: 'EmployeeDetailsWidget/listenMobileAction/promoteAdmin exception',
          });
        }
      },
    );
    return () => {
      unsubscribe();
    };
  }, [
    trustedPortalQueueMessageId,
    mobileRequestOverlayQueueMessageId,
    closeMobileRequestOverlay,
    showSuccess,
    showError,
    isTrustedPortalEnabled,
    t,
  ]);

  useEffect(() => {
    const queueMessageId = mobileRequestOverlayQueueMessageId || trustedPortalQueueMessageId;
    if (!queueMessageId) {
      return undefined;
    }
    const unsubscribe = listenMobileAction(
      RealtimeAPIEventTypeEnum.QueueMessageResultNotification,
      queueMessageId,
      QueueEventEnum.revokeAdmin,
      async (event) => {
        try {
          const { status } = event;
          if (!isTrustedPortalEnabled) {
            closeMobileRequestOverlay();
          } else {
            setTrustedPortalQueueMessageId(null);
          }
          if (status) {
            showError({ text: t('mobileRequestNotifications.revokeAdminRejected') });
          } else {
            showSuccess({ text: t('mobileRequestNotifications.revokeAdminApproved') });
          }
          setIsAdminLoading(false);
        } catch (e) {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, {
            message: 'EmployeeDetailsWidget/listenMobileAction/revokeAdmin exception',
          });
        }
      },
    );
    return () => {
      unsubscribe();
    };
  }, [
    trustedPortalQueueMessageId,
    mobileRequestOverlayQueueMessageId,
    closeMobileRequestOverlay,
    showSuccess,
    showError,
    isTrustedPortalEnabled,
    t,
  ]);

  const handleCloseMobileRequestOverlay = useCallback(() => {
    setIsAdminLoading(false);
    closeMobileRequestOverlay();
  }, [closeMobileRequestOverlay]);

  const handleEmployeeEdit = useCallback((value: IEditEmployeeModalReturnValue) => {
    setIsEmployeeEditLoading(true);
    mutateEmployee([
      createReplacePatchOperation(EmployeePatchOperationEnum.Name, value.name),
    ], {
      onError: () => showError({ text: t('common.somethingWentWrong') }),
      onSuccess: () => handleEditEmployeeModalClose(),
      onSettled: () => setIsEmployeeEditLoading(false),
    });
  }, [mutateEmployee, handleEditEmployeeModalClose, showError, t]);

  const isLoading = isGetEmployeeAccountByIdLoading || isGetOrganizationScimSettingsLoading;
  const isError = isGetEmployeeAccountByIdError || isGetOrganizationScimSettingsError;
  const { secretToken } = scim ?? {};

  if (isError) {
    return null;
  }

  return (
    <EntityDetailsContainer container isLoading={isLoading}>
      <Grid container item justifyContent="space-between" flexWrap="nowrap">
        <Box mt={-1} ml={-1}>
          <EditableContainer
            container
            onClick={handleEditEmployeeModalOpen}
            disabled={!!externalCreationId && !!secretToken}
          >
            <TypographyWithTooltip variant="subtitle1" breakWord>
              {name || t('employeeDetailsWidget.name')}
            </TypographyWithTooltip>
          </EditableContainer>
        </Box>
        <Grid item>
          <Typography
            variant="subtitle1"
            color={STATUS_COLORS[employeeAccountStatus as keyof typeof STATUS_COLORS]}
          >
            {t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[
              employeeAccountStatus as keyof typeof EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS
            ])}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item mb={1}>
        <TypographyWithTooltip variant="body1" color={S5} breakWord>
          {email}
        </TypographyWithTooltip>
      </Grid>
      {!!externalCreationId && !!secretToken && (
        <Grid container item>
          <Label text={t('employeeDetailsWidget.scim')} />
        </Grid>
      )}
      <Grid container item flexWrap="nowrap">
        <Grid item container alignItems="center">
          {isAdminLoading ? (
            <Grid item mt="9px">
              <Spinner />
            </Grid>
          ) : (
            <>
              <Grid item mt={1}>
                <Typography variant="subtitle1" color={isAdmin ? AG1 : S4}>
                  {t('employeeDetailsWidget.admin')}
                </Typography>
              </Grid>
              <Box mr={2} />
              <Switch
                checked={isAdmin}
                disabled={isSwitchDisabled}
                onChange={handleAdminRightsChange}
              />
            </>
          )}
        </Grid>
        <Grid item container flexDirection="column" alignItems="end">
          <Grid item textAlign="end">
            <Typography variant="caption" color={S5}>
              {t('employeeDetailsWidget.lastActivity')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color={S5}>
              {lastActivity}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {isEditEmployeeModalOpen && (
        <EditEmployeeModal
          isOpen={isEditEmployeeModalOpen}
          onClose={handleEditEmployeeModalClose}
          onSubmit={handleEmployeeEdit}
          name={name}
          isLoading={isEmployeeEditLoading}
        />
      )}
      <MobileRequestOverlay
        queueMessageId={mobileRequestOverlayQueueMessageId!}
        isOpen={isMobileRequestOverlayOpen}
        onCancel={handleCloseMobileRequestOverlay}
      />
    </EntityDetailsContainer>
  );
};

export default memo(EmployeeDetailsWidget);
