import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import {
  TOnboardingSendMessageWithTimeoutOperationErrors,
} from '../sendMessageWithTimeoutOperation/errors';
import {
  TOnboardingPostMessageWithTimeoutOperationErrors,
} from '../postMessageWithTimeoutOperation/errors';

export class OnboardingInitialExtensionCheckUnsupportedBrowserError extends BaseEitherError {}

export class OnboardingInitialExtensionCheckError extends BaseEitherError {}

export type TOnboardingInitialExtensionCheckOperationErrors =
  | OnboardingInitialExtensionCheckUnsupportedBrowserError
  | OnboardingInitialExtensionCheckError
  | TOnboardingSendMessageWithTimeoutOperationErrors
  | TOnboardingPostMessageWithTimeoutOperationErrors;
