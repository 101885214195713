import {
  ElevationEnum,
  G100,
  G500,
  HeroGrid,
  HeroIconWithBorder,
  HeroPanel,
  HeroSpinner,
  HeroTypography,
  P100,
  P300,
  P500,
  S6,
  SizeEnum,
  TypographyVariantEnum,
  TypographyWeightEnum,
  useTranslations,
} from '@uniqkey-frontend/shared-app';

const Icon = () => ( // TODO REBRAND: Remove
  <HeroSpinner size={20} />
);

const CommunicationMessage = () => {
  const { t } = useTranslations();
  return (
    <HeroPanel backgroundColor={G100} elevation={ElevationEnum.XS2}>
      <HeroGrid
        container
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={SizeEnum.Size16}
      >
        <HeroGrid
          mb={SizeEnum.Size24}
          width={50} // TODO REBRAND: check if width needed (check in Safari)
        >
          <HeroIconWithBorder // TODO REBRAND: Replace with HeroIconWithPolygonContainer
            IconComponent={Icon}
            iconColor={P500}
            iconBorderColor={P300}
            iconBackgroundColor={P100}
          />
        </HeroGrid>
        <HeroGrid mb={SizeEnum.Size4}>
          <HeroTypography
            variant={TypographyVariantEnum.XL}
            fontWeight={TypographyWeightEnum.Medium}
            color={S6}
          >
            {t('onboarding.processing.header')}
          </HeroTypography>
        </HeroGrid>
        <HeroGrid>
          <HeroTypography variant={TypographyVariantEnum.MD} color={G500}>
            {t('onboarding.processing.description')}
          </HeroTypography>
        </HeroGrid>
      </HeroGrid>
    </HeroPanel>
  );
};

export default CommunicationMessage;
