import type { Either } from '@uniqkey-frontend/shared-app';
import {
  UserLoginStepOneResponse,
  UserLoginStepOneRequest,
} from '@uniqkey-backend-organization-extension/api-client';
import {
  OnboardingLoginStepOneError,
  type TOnboardingLoginStepOneOperationErrors,
} from './errors';
import APIClientsProvider from '../../apiClientsProvider';
import Helpers from '../../helpers';
import Axios from '../../axios';

interface ILoginStepOneOperationParams extends UserLoginStepOneRequest {}

const loginStepOneOperation = async (
  params: ILoginStepOneOperationParams,
): Promise<Either<UserLoginStepOneResponse, TOnboardingLoginStepOneOperationErrors>> => {
  try {
    const { email } = params;

    const result = await APIClientsProvider
      .getUserAPIClient(Axios.publicAxiosInstance)
      .apiV1UserLoginStepOnePost({ email })
      .then(Helpers.dataExtractor<UserLoginStepOneResponse>());
    return result;
  } catch (e) {
    return new OnboardingLoginStepOneError();
  }
};

export default loginStepOneOperation;
