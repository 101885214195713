import {
  AlertCircleIcon,
  E300,
  E50,
  E500,
  ElevationEnum,
  G100,
  G500,
  HeroGrid,
  HeroIconWithBorder,
  HeroPanel,
  HeroTypography,
  G900,
  SizeEnum,
  TypographyVariantEnum,
  TypographyWeightEnum,
  useTranslations,
} from '@uniqkey-frontend/shared-app';

const ErrorMessage = () => {
  const { t } = useTranslations();
  return (
    <HeroPanel backgroundColor={G100} elevation={ElevationEnum.XS2}>
      <HeroGrid
        container
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={SizeEnum.Size16}
      >
        <HeroGrid mb={SizeEnum.Size24}>
          <HeroIconWithBorder // TODO REBRAND: Replace with HeroIconWithPolygonContainer
            IconComponent={AlertCircleIcon}
            iconColor={E500}
            iconBorderColor={E300}
            iconBackgroundColor={E50}
          />
        </HeroGrid>
        <HeroGrid mb={SizeEnum.Size4}>
          <HeroTypography
            variant={TypographyVariantEnum.XL}
            fontWeight={TypographyWeightEnum.Medium}
            color={G900}
          >
            {t('onboarding.processing.error.title')}
          </HeroTypography>
        </HeroGrid>
        <HeroGrid>
          <HeroTypography variant={TypographyVariantEnum.MD} color={G500}>
            {t('onboarding.processing.error.description')}
          </HeroTypography>
        </HeroGrid>
      </HeroGrid>
    </HeroPanel>
  );
};

export default ErrorMessage;
